import cloneDeep from 'lodash/cloneDeep';
import { useSnapshot as useSnapshotValtio } from 'valtio';

import { isObjectish } from './object';
import { uncheckedIndexAccess_UNSAFE } from './uncheckedIndexAccess_UNSAFE';

/**
 * A specific valtio snapshot, such as `useSnapshot(p.something)` will go stale
 * if `p.something` is directly assigned: `p.something = {}`. This utility
 * avoids ever directly assigning an object, only primitive properties (while
 * also handling deleted properties).
 */
function snapshotSafeSet<T, F extends T | Partial<T>>(
  target: T,
  from: F,
  snapshotSafeDepth: number,
  omittedPropertiesAreDeleted: boolean
): void {
  let depth = 0;

  function go<O, N extends O>(proxy: O, initial: N) {
    if (
      typeof initial === 'object' &&
      initial &&
      typeof proxy === 'object' &&
      proxy
    ) {
      // Track the keys so we optionally know which to delete
      const untouchedKeys = new Set(
        typeof proxy === 'object' && proxy ? Object.keys(proxy) : []
      );
      Object.keys(initial).forEach((key) => {
        const original = uncheckedIndexAccess_UNSAFE(proxy)[key];
        const next = uncheckedIndexAccess_UNSAFE(initial)[key];

        // We have now touched this key
        untouchedKeys.delete(key);

        if (
          typeof original === 'object' &&
          original &&
          typeof next === 'object' &&
          next &&
          depth < snapshotSafeDepth
        ) {
          // both the original value and next value are objects, meaning a deep
          // assignment is likely necessary.
          depth++;
          go(original, next);
          depth--;
        } else {
          // one or both original and next are a primitive, or the safety depth
          // was exceeded. Do a plain assignment. This will cause a stale
          // snapshot!
          uncheckedIndexAccess_UNSAFE(proxy)[key] = next;
        }
      });

      if (omittedPropertiesAreDeleted) {
        // delete the untouched keys from the target if enabled (likely a `set`
        // operation)
        untouchedKeys.forEach((key) => {
          delete uncheckedIndexAccess_UNSAFE(proxy)[key];
        });
      } else {
        // otherwise skip the untouched keys (likely a partial update operation)
      }
    }
  }

  go(target, from as T);

  if (
    depth > 0 &&
    depth >= snapshotSafeDepth &&
    process.env.NODE_ENV !== 'production'
  ) {
    console.warn(
      `valtio.snapshotSafeSet() hit the max depth of', ${snapshotSafeDepth}`
    );
    console.trace(`valtio.snapshotSafeSet callstack`);
  }
}

function notSerializableReason(value: unknown) {
  if (
    value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    (value.constructor !== Object || !('toJSON' in value))
  ) {
    return 'instance';
  }

  if (typeof value === 'function') {
    return 'function';
  }

  if (typeof value === 'symbol') {
    return 'symbol';
  }

  if (value === Infinity || value === -Infinity) {
    return 'infinity';
  }

  return '';
}

class NotSerializableError extends Error {
  constructor(key: string, reason: string, value: unknown) {
    super(`Value at key "${key}" (${reason}) is not serializable: ${value}`);
    this.name = 'NotSerializableError';
  }
}

function serializableReplacer(key: string, value: unknown) {
  const reason = notSerializableReason(value);
  if (reason) throw new NotSerializableError(key, reason, value);
  return value;
}

export const ValtioUtils = {
  set<S, K extends keyof S>(
    target: IsNotPrimitive<S>,
    key: K,
    src: IsNotPrimitive<S>[K],
    snapshotSafeDepth = 20
  ): void {
    if (!isObjectish(target[key]) || !isObjectish(src)) {
      // special case: no target object to set
      target[key] = src;
      return;
    } else snapshotSafeSet(target[key], src, snapshotSafeDepth, true);
  },

  update<T>(
    target: IsNotPrimitive<T>,
    update: Partial<IsNotPrimitive<T>>,
    snapshotSafeDepth = 20
  ): void {
    if (!isObjectish(target)) {
      throw new Error('Cannot update a primitive!');
    }
    snapshotSafeSet(target, update, snapshotSafeDepth, false);
  },

  reset<T>(
    target: IsNotPrimitive<T>,
    initial: IsNotPrimitive<T>,
    snapshotSafeDepth = 20
  ): void {
    const resetObj = cloneDeep(initial);
    snapshotSafeSet(target, resetObj, snapshotSafeDepth, true);
  },

  /**
   * Clone the valtio proxy/snapshot into a "plainer" object. Since this uses
   * cloneDeep, it will technically still contain functions, dates, and other
   * class instances as a best effort. But it's best to not rely on these
   * instances once detatched.
   */
  detachCopy<S>(inc: S): S {
    return cloneDeep(inc);
  },

  /**
   * Serialize the valtio proxy into a JSON string. This will throw if the
   * values are not serializable, such as functions, class instances, symbols,
   * bigints, etc.
   */
  serialize<S>(inc: S): string {
    return JSON.stringify(inc, serializableReplacer);
  },
};

/**
 * Indicates that this data structure is a valid target for useSnapshot();
 */
export type ValtioSnapshottable<T> = T & { __ValtioSnapshottable: true };

export type InnerValtioSnapshottable<S> = S extends ValtioSnapshottable<infer T>
  ? T
  : never;

/**
 * Indicates a type that can be used a snapshot. Since we may use the proxy state itself in some cases, we support both
 * a snapshot from 'useSnapshot' or a readonly.
 */
export type Snapshot<T extends object> =
  | Readonly<T>
  | ReturnType<typeof useSnapshot<T>>;

/**
 * NoOp. Mark this data structure as snapshottable for TS. This prevents the
 * branded property from appearing in type signatuers for
 * Object.keys/entries/values.
 */
export function markSnapshottable<T>(snapshottable: T): ValtioSnapshottable<T> {
  return snapshottable as ValtioSnapshottable<T>;
}

/**
 * NoOp. Unmark this data structure as snapshottable for TS.
 */
export function unmarkSnapshottable<T>(
  snapshottable: ValtioSnapshottable<T>
): T {
  return snapshottable as T;
}

/**
 * Wrapper around useSnapshot that only accepts something explicitly marked as
 * snapshottable.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSnapshot<T>(snapshottable: ValtioSnapshottable<T>) {
  return useSnapshotValtio(snapshottable);
}
