import { uncheckedIndexAccess_UNSAFE } from './uncheckedIndexAccess_UNSAFE';

/**
 * This utility uses types to enforce the optional properties when assigning.
 *
 * Consider:
 *
 * ```
 * const incoming = { a: 1, b: 2 };
 * const destination = { a: 0, b: 2, c: 3 };
 *
 * // We want the absence of `c` to indicate that it should be deleted on the destination
 * Object.assign(destination, incoming); // { a: 1, b: 2, c: 3 } !!!!
 * safeAssign(destination, { c: undefined }, incoming); // { a: 1, b: 2, c: undefined }
 * ```
 * This is most commonly an issue in Firebase, where deleting a property `{ a:
 * null }` on write does not emit `{ a: undefined }` as an event, you will just
 * get `{}`.
 *
 * @param destination the final destination of all properties
 * @param undefineds the properties that can optionally be undefined, so that if
 * they are not present in `incoming`, we treat them as a deletion
 * @param incoming a subset of the properties
 */
export function safeAssign<T extends object>(
  destination: Nullable<T>,
  undefineds: WithOptionalsOnly<T>,
  ...incoming: T[]
) {
  const safedIncoming = Object.assign({}, undefineds, ...incoming);

  if (!destination) return safedIncoming;

  for (const key in safedIncoming) {
    if (safedIncoming[key] !== uncheckedIndexAccess_UNSAFE(destination)[key]) {
      uncheckedIndexAccess_UNSAFE(destination)[key] = safedIncoming[key];
    }
  }

  return destination;
}

export const isObjectish = (val: unknown) =>
  Object.prototype.toString.call(val) === '[object Object]';
